import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit, AfterViewInit {

  _value;
  @Input()
  set value(val) {
    this._value = !val && val === '' ? null : `${val}`;
    this.ngForms.controls.customValue.setValue(this._value);
  }

  _defaultValue;
  _list: Array<any> = [];
  @Input()
  set list(val) {
    this._list = val;
    this._defaultValue = val && val.length && val[0].Value;
  }

  // Types { 'currency' }
  _type;
  @Input()
  set inputType(type) {
    this._type = type;
  }

  _disabled: boolean = false;
  @Input() 
  set disabled(status) {
    this._disabled = status;
    if (status) {
      this.ngForms.controls.customValue.disable();
    } else {
      this.ngForms.controls.customValue.enable();
    }
  }

  ngForms: FormGroup;
  showCustomInput: boolean = false;

  customPatterns = { '9': { pattern: new RegExp('\[0-9\]')} };

  @Output() valueChanges = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder
  ) {
    this.ngForms = fb.group({
      customValue: ['']
    });
  }

  ngOnInit(): void {
    this.initialField();
  }

  ngAfterViewInit() {
    this.initialField();
  }

  reset() {
    this.closeCustomField();
  }

  initialField() {
    this.showCustomInput = false;
    const _value = this.ngForms.controls.customValue.value;
    if (!_value || !this._list || !this._list.length) {
      return;
    }
    const find = this._list.find(i => i.Value === _value);
    this.showCustomInput = !(find && find.Value);
  }

  selectValueChange(value) {
    if (value === '-1') {
      if (!this.showCustomInput) {
        this.ngForms.controls.customValue.setValue('');
        this.valueChanges.emit('');
      }
      this.showCustomInput = true;
    } else {
      this.valueChanges.emit(value);
    }
  }

  inputValueChange(value) {
    this.valueChanges.emit(value);
  }

  closeCustomField() {
    if (this._disabled) {
      return;
    }
    this.showCustomInput = false;
    this.ngForms.controls.customValue.setValue(this._defaultValue);
    this.valueChanges.emit(this._defaultValue);
  }
}
