import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '..';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  private userInfo = null;

  constructor(
    private router: Router,
    private _loginService: LoginService
  ) { }

  public getUserInfo() {
    return this.userInfo;
  }

  loanUserInfo() {
    return new Promise((resolve, reject) => {
      const postData = {
        refreshToken: localStorage.getItem('refresh_token'),
        email: localStorage.getItem('email')
      };
      this._loginService.refreshToken(postData).subscribe(res => {
        if (localStorage.getItem('eagle_auth')) {
          localStorage.setItem('eagle_auth', res.authTokens.idToken);
          localStorage.setItem('refresh_token', res.authTokens.refreshToken);
          localStorage.setItem('accessToken', res.authTokens.accessToken);
          resolve(true);
        }
      }, err => {
        console.error(err);
        resolve(true);
        localStorage.removeItem('eagle_auth');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('accessToken');
        this.router.navigate(['/login']);
      });
    });
  }
}
